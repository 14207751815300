<template>
<div>
    <VestAmount />
</div>
</template>

<script>
import VestAmount from '../../components/dashboard/vestfolder/VestAmount.vue'
export default {
    components: {
        VestAmount
    }
}
</script>

